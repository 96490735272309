import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient) {}

  public createPerson(data: any) {
    return this.http.post('https://xlxqpe.api-sa2.infobip.com/people/2/persons', data, {
      headers: {
        Authorization: '53710642578421ea762ea12f7c5c6704-51f0816c-8655-4498-a29d-46db0e8507eb',
      },
    });
  }
}
