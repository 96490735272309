export const environment = {
  production: false,
  api: 'https://api.testship.net/13BFF7C7-5671-EFB1-FFB0-7895530DFF00/B49E0FE7-968E-41F9-BE45-19BC553B5111',
  awb: 'https://doc-sb.testship.net',
  exportBulk: 'https://services-sb.testship.net/',
  awbApi: 'https://api.testship.net/13BFF7C7-5671-EFB1-FFB0-7895530DFF00/3FF04A05-818B-4B00-9641-DF1A308C6E47/services/awb/',
  googleMapsKey: 'AIzaSyAZfuZJA3-KQ03st0ZxLSqz6_--xl7-Kkk',
  configParams: {
    limitOfAWBsToPrint: 500,
    limitOfPODsToPrintSingle: 50,
    limitOfPODsToPrintList: 300,
    limitOfMaxBulkOrders: 2000,
    limitOfInventoryItems: 10000,
  },
  chat: {
    appId: 'wymbkmiz',
    enabled: true,
  },
};
